export default [
    {
        path: '/admin/hearts/heart-exchanges',
        name: 'heart-exchanges',
        component: () => import('@/views/pages/cloudx/heartManagement/Heart-exchanges.vue'),
    },    {
        path: '/admin/hearts/matches',
        name: 'matches',
        component: () => import('@/views/pages/cloudx/heartManagement/Matches.vue'),
    },
    // {
    //     path: '/admin/hearts/revealed',
    //     name: 'revealed',
    //     component: () => import('@/views/pages/cloudx/heartManagement/Revealed.vue'),
    // },
]