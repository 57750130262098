import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    userUpdate : 'USER_UPDATE',
    userDelete : 'USER_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        users: [],
        logs: [],
        summary: {},
        details: {},
        userLoading: false,
        userError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations : {
        ...mutations,

        SET_USERS (state, users) {
            state.users = users;
        },

        SET_USER_DETAILS (state, user) {
            state.details = user;
        },

        USER_LOADING (state, loading) {
            state.userLoading = loading;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        USER_ERROR(state, error) {
            state.submitError = error;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },

        SET_LOGS (state, logs) {
            state.logs = logs;
        },

        SET_SUMMARY (state, summary) {
            state.summary = summary;
        }
    },

    actions: {
        async updateUser({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.countryUpdate,
                async () => {
                    let payloadData={ 
                        name: payload.name
                    }
                    let magic=encode(payloadData)
                    response = await axios.patch(V1API.update_user+"/"+payload.id,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data) 
                        return data.message
                    })

                    dispatch('fetchArea');

                }
            )
            return response
        },

        async deleteUser({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.countryDelete,
                async () => {

                    response = await axios.delete(V1API.delete_area+'/'+payload).then(result=>{
                        let data=decode(result.data)

                        return data.message

                    });
                    dispatch('fetchArea');
                }
            )

            return response
        },

        async fetchUsers ({ commit }) {
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_users);
                let response=decode(result.data.data)
                commit('SET_USERS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async fetchUserDetails ({ commit }, payload) {
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_user_details + payload);
                let response=decode(result.data.data)
                commit('SET_USER_DETAILS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async fetchUsersByStatus ({ commit },payload) {
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_users_by_status + payload);
                let response=decode(result.data.user_info)
                commit('SET_USERS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async fetchUsersByPremiumStatus ({ commit },payload) { 
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_users_by_premium_status + payload);
                let response=decode(result.data.user_info)
                commit('SET_USERS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async fetchUsersByGender ({ commit },payload) { 
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_users_by_gender + payload);
                let response=decode(result.data.user_info)
                commit('SET_USERS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async updateAccountStatus({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let response = await axios.get(V1API.update_account_status + payload.id + '/' + payload.status).then(result=>{
                    let data=result.data

                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                // dispatch('fetchUsers')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async updatePremiumStatus({commit}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{ 
                let response = await axios.get(V1API.update_premium_status + payload.pid + '/' + payload.status).then(result=>{
                    let data=result.data

                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchActivityLogByUser ({ commit }, payload) { 
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_activity_log_by_user + payload);
                let response=decode(result.data.activity_log.activity_log)
                commit('SET_LOGS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async fetchSummaryByUser ({ commit }, payload) { 
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_summary_by_user + payload);
                let response=decode(result.data)
                commit('SET_SUMMARY', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async updatePersonalInformations ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{ 
                let response = await axios.patch(V1API.update_personal_informations + payload.pid, payload.user).then(result=>{
                    let data=result.data

                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },

    getters: {}
}