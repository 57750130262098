import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    adminUpdate : 'ADMIN_UPDATE',
    adminDelete : 'ADMIN_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        admins: [],
        details: {},
        adminLoading: false,
        adminError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations : {
        ...mutations,

        SET_ADMIN (state, admins) {
            state.admins = admins;
        },

        SET_ADMIN_DETAILS (state, admin) {
            state.details = admin;
        },

        ADMIN_LOADING (state, loading) {
            state.adminLoading = loading;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        ADMIN_ERROR(state, error) {
            state.submitError = error;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateAdmin({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.adminUpdate,
                async () => {
                    let payloadData={ 
                        id: payload.id,
                        first_name: payload.first_name,
                        last_name: payload.last_name,
                        email: payload.email
                    }
                    let magic=encode(payloadData)
                    response = await axios.patch(V1API.update_admin+"/"+payload.id,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data) 
                        return data.message
                    })

                    dispatch('fetchAdminList');

                }
            )
            return response
        },

        async deleteAdmin({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.adminDelete,
                async () => {

                    response = await axios.delete(V1API.delete_admin+'/'+payload).then(result=>{
                        let data=decode(result.data)

                        return data.message

                    });
                    dispatch('fetchAdminList');
                }
            )

            return response
        },

        async fetchAdminList ({ commit }) {
            commit('ADMIN_LOADING', true);
            commit('ADMIN_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_admin_list);
                let response=decode(result.data.data)
                commit('SET_ADMIN', response); 

            }catch(error) {

                commit('ADMIN_ERROR', true);

            }
            commit('ADMIN_LOADING', false);
        },
        
        async addAdmin({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.create_new_admin,magic.encodedPayload,magic.config).then(result=>{
                    let data=decode(result.data)

                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchAdminList')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },
    },



    getters: {}
}