export default [
    {
      path: '/admin/users',
      name:'users',
      component:()=> import('@/views/pages/cloudx/subscribers/Users.vue')
    },
    {
      path: '/admin/user/profile/:id',
      component:()=> import('@/views/pages/cloudx/subscribers/User-profile.vue')
    },
    {
      path: '/admin/deleted-profiles',
      name:'deleted-profiles',
      component:()=> import('@/views/pages/cloudx/subscribers/Deleted-profiles.vue')
    },
    {
      path: '/admin/incompatible-profiles',
      name:'incompatible-profiles',
      component:()=> import('@/views/pages/cloudx/subscribers/Incompatible-profiles.vue')
    },
    {
      path: '/admin/active-profiles',
      name:'active-profiles',
      component:()=> import('@/views/pages/cloudx/subscribers/Active-profiles.vue')
    },
    {
      path: '/admin/premium-users',
      name:'premium-users',
      component:()=> import('@/views/pages/cloudx/subscribers/Premium-users.vue')
    },
    {
      path: '/admin/regular-users',
      name:'regular-users',
      component:()=> import('@/views/pages/cloudx/subscribers/Free-users.vue')
    },
    {
      path: '/admin/male-users',
      name:'male-users',
      component:()=> import('@/views/pages/cloudx/subscribers/Male-users.vue')
    },
    {
      path: '/admin/female-users',
      name:'female-users',
      component:()=> import('@/views/pages/cloudx/subscribers/Female-users.vue')
    },
    {
      path: '/admin/leads',
      name:'leads',
      component:()=> import('@/views/pages/cloudx/subscribers/Leads.vue')
    },
    {
      path: '/admin/otp-verified',
      name:'otp-verified',
      component:()=> import('@/views/pages/cloudx/subscribers/OTP-verified.vue')
    },
    {
      path: '/admin/review',
      name:'review',
      component:()=> import('@/views/pages/cloudx/subscribers/Review.vue')
    },
    {
      path: '/admin/edit',
      name:'edit',
      component:()=> import('@/views/pages/cloudx/subscribers/Edit.vue')
    },
    {
      path: '/admin/further-correction-due',
      name:'correction-due',
      component:()=> import('@/views/pages/cloudx/subscribers/Further-correction.vue')
    },
    {
      path: '/admin/pending-activation',
      name:'pending',
      component:()=> import('@/views/pages/cloudx/subscribers/Pending.vue')
    },
]